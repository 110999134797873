<template>
  <f7-page class="product-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProductNavigationComponent :title="title" type="back" searchname="searchproductlist" :f7router="f7router" :f7route="f7route" @searched="onSearch" />
    </template>

    <Suspense>
      <FullWidthBannerListComponent v-if="mode === 'SPECIAL_PRICE'" type="SPECIALPRICEPAGE" divider="true" />
    </Suspense>

    <Suspense>
      <FullWidthBannerListComponent v-if="mode === 'GROUPBUY'" type="GROUPBUYPAGE" divider="true" />
    </Suspense>

    <ProductListComponent v-if="mode" ref="productListComponent" :isLoader="true" :mode="mode" :layout="layout" :f7route="f7route" :size="25" :options="true" :showNotFoundComponent="true" :showMainLoading="true" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, defineAsyncComponent } from 'vue'

export default defineComponent({
  name: 'ProductListPage',
  components: {
    ProductNavigationComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductNavigationComponent.vue')),
    ProductListComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-list" */ /* webpackMode: "lazy" */ '@/components/ProductListComponent.vue')),
    FullWidthBannerListComponent: defineAsyncComponent(() => import(/* webpackChunkName: "full-width-banner" */ /* webpackMode: "lazy" */ '@/components/FullWidthBannerListComponent.vue'))
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const title = ref('')
    const layout = ref('GRID')
    const mode = ref('')

    const productListComponent = ref(false)
    const showPreloader = ref(false)

    return {
      title,
      mode,
      layout,
      productListComponent,
      showPreloader
    }
  },
  mounted() {
    this.mode = this?.f7route?.query?.mode ? this.f7route.query.mode : 'ALL'
    this.layout = this?.f7route?.query?.layout ? this.f7route.query.layout : 'GRID'

    if (this.mode === 'SPECIAL_PRICE') {
      this.title = this.$t.getTranslation('LBL_618_SPECIAL_PRICE')
    }

    if (this.mode === 'GROUPBUY') {
      //this.title = this.$t.getTranslation('LBL_618_GROUP_BUY')
      this.title = this.$t.getTranslation('LBL_GROUP_BUY')
    }
  },
  methods: {
    async loadMore() {
      this.showPreloader = true
      await this.$refs.productListComponent.loadMore()
      this.showPreloader = false
    },
    onSearch(searchValue) {
      this?.$refs?.productListComponent?.search(searchValue)
    }
  }
})
</script>
